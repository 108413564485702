import { Route } from '@angular/router';
import { claimDetailsRoute } from './pages/claim-details/claim-details.routes';
import { travelDetailsRoute } from './pages/travel-details/travel-details.routes';

export const travelRoutes: Route = {
    loadComponent: () => import('./travel.component'),
    children: [
        { path: '', redirectTo: 'contracts', pathMatch: 'full' },
        {
            path: 'contracts',
            loadComponent: () => import('./pages/contracts/contracts.component'),
            title: 'contracts',
        },
        {
            path: 'claims',
            loadComponent: () => import('./pages/claims/claims.component'),
            title: 'claims',
        },
        {
            path: 'claims/:claimId',
            ...claimDetailsRoute,
        },
        {
            path: 'travels',
            loadComponent: () => import('./pages/travels/travels.component'),
            title: 'travels',
        },
        {
            path: 'travels/:travelId',
            ...travelDetailsRoute,
            title: 'travelDetails',
        },
        {
            path: 'organizations',
            loadComponent: () => import('./pages/framework-contracts/framework-contracts.component'),
            title: 'frameworkContracts',
        },
        {
            path: 'travel-warnings',
            loadComponent: () => import('./pages/travel-warnings/travel-warnings.component'),
            title: 'travel-warnings',
        },
        {
            path: 'open-reversals',
            loadComponent: () => import('./pages/open-reversals/open-reversals.component'),
            title: 'openReversals.title',
        },
    ],
};
