import { HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding, withRouterConfig } from '@angular/router';
import { SharedModule } from '@luis/common/shared';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    provideCommon,
    providePageTitleStrategy,
    provideValidationMessages,
    withDefaultValidationMessages,
    withIbanValidationMessages,
    withJMBGValidationMessages,
} from '@nuis/common';
import { withDocumentValidationMessages, withNotesValidationMessages } from '@nuis/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { provideStartupService } from './startup.service';

const MultiHttpLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
    return new MultiTranslateHttpLoader(http, ['./i18n/', './shared/i18n/']);
};

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            appRoutes,
            withComponentInputBinding(),
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
            }),
        ),
        provideHttpClient(withInterceptorsFromDi()),

        provideStartupService(),

        provideCommon(),
        providePageTitleStrategy('LUIS'),
        provideValidationMessages(
            withDefaultValidationMessages(),
            withIbanValidationMessages(),
            withJMBGValidationMessages(),
            withNotesValidationMessages(),
            withDocumentValidationMessages(),
        ),

        importProvidersFrom(
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: MultiHttpLoaderFactory,
                    deps: [HttpBackend],
                },
                isolate: false,
                extend: true,
            }),

            DialogService,

            SharedModule.forRoot(environment),
        ),
    ],
};
